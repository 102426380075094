import { Occ } from '../occ';
import { CartType } from './cart.model';
import { Image } from './image.model';
import { Price } from './misc.model';

export import PackagingType = Occ.ArticlePackagingEnum;

export enum SubstituteType {
  Alternative = 'Alternative',
  Replacement = 'Replacement',
  Recommendation = 'Recommendation',
}

export enum SubstituteRefType {
  Article = 'Article',
  Product = 'Product',
}

export import ProductDetailsTopic = Occ.ProductDetailsTopic;

export enum ArticleStatus {
  ZN = 'ZN', // Is status for discontinued articles. sold-out.
  ZA = 'ZA', // Sell-out (incl. write-off)
  ZT = 'ZT', // Sell-out (before write-off)
  ZJ = 'ZJ', // Customer owned stock
  ZG = 'ZG', // Semi-stock articles
  Z1 = 'Z1', // Indent
  ZY = 'ZY', // Unreleased
  ZV = 'ZV', // Catalogue article // no stock
  ZZ = 'ZZ', // Promotional
  ZF = 'ZF', // Locally customer unique
  ZD = 'ZD', // ZD ZE and XU - customer unique article (local level)
  ZE = 'ZE', // ZD ZE and XU - customer unique article (local level)
  XU = 'XU', // ZD ZE and XU - customer unique article (local level)
}

export enum AddonSortTypes {
  ADDONS_DEFAULT = 'default',
  ADDONS_ARTICLE_NAME = 'article_name',
  ADDONS_ARTICLE_NUMBER = 'article_number',
}

export interface SubstituteRef {
  code?: string;
  substituteType?: SubstituteType;
  refType?: SubstituteRefType;
}

export interface Substitutes extends Omit<Occ.Substitutes, 'refs'> {
  refs?: SubstituteRef[];
}

export interface Category extends Omit<Occ.Category, 'superCategoryCode'> {
  superCategoryRef?: string;
  subCategories?: Array<Category>;
}

export type ArticleRef = string;
export type CategoryRef = string;

export enum CampaignItemSizeType {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  EXTRA_SMALL = 'EXTRA_SMALL',
}
export import SeoMeta = Occ.SeoMeta;

export interface Product extends Omit<Occ.Product, 'articles'> {
  articles?: Array<ArticleRef>;
  colors?: Array<string>;
  status?: string;
  categoryRef?: string;
  overlayNew?: boolean;
  articlePickerAttributes?: Array<ArticlePickerAttribute>;
  size?: CampaignItemSizeType;
  notAvailable?: boolean;
  ticket?: string;
}

export import AlternativeProducts = Occ.AlternativeProducts;

export import AlternativeProductRef = Occ.AlternativeProductRef;

export interface A4SampleProduct {
  code?: string;
  name?: string;
  url?: string;
  thumbnail?: Image;
}

export import SampleInfo = Occ.SampleInfo;

export import OutgoingInfo = Occ.OutgoingInfo;

export import UniqueSellingPoint = Occ.UniqueSellingPoint;

export import ProductDownloadable = Occ.ProductDownloadable;

export interface ArticlePickerEvent {
  attributeCode: string;
  value: string;
  auto: boolean;
}

export interface ArticlePickerAttribute extends Occ.ClassificationAttributeData {
  options?: ArticlePickerAttributeOption[];
}

export interface ArticlePickerAttributeOption {
  value?: string;
  label?: string;
  rawValue?: string;
  disabled?: boolean;
}

export import ProductExtraInfo = Occ.ProductExtraInfo;

export import ProductBrand = Occ.ProductBrand;

export import ProductBrandsWrapper = Occ.ProductBrandsWrapper;

export import ProductArticleAssortment = Occ.Assortment;

export import ProductCertification = Occ.ProductCertification;

export import ProductCertificateCustomization = Occ.ProductCertificateGroupCustomizationEnum;

export import ProductAttributeTable = Occ.AttributeTable;

export import ProductAttributeRow = Occ.AttributeRow;

export import ProductTechnicalAttribute = Occ.TechnicalAttribute;

export enum ProductRelationTargetType {
  ARTICLE = 'Article',
  PRODUCT = 'Product',
  PYPRODUCT = 'PyProduct',
}

export import ProductRelation = Occ.ProductRelation;

export interface EntityMap<T> {
  [id: string]: T;
}

export import SpecialMessage = Occ.SpecialMessage;

export interface PrePrintedConfigurationWithCountry {
  value: string;
  label: string;
}

export interface Article extends Omit<Occ.Article, 'classificationAttributes' | 'articlePrePrintedConfigurationWithCountry'> {
  articleName?: string;
  size?: string;
  articleStatus?: ArticleStatus;
  price?: Price;
  classificationAttributes?: EntityMap<ClassificationAttribute>;
  datasheet?: {
    url?: string;
  };
  overlayNew?: boolean;
  cartType?: CartType;
  articlePrePrintedConfigurationWithCountry?: PrePrintedConfigurationWithCountry[];
  ticket?: string;
}

export import ArticleExtraInfo = Occ.ArticleExtraInfo;

export import ProductCertificateGroup = Occ.ProductCertificateGroup;

export import Certification = Occ.Certification;

export import ArticleAttribute = Occ.Attribute;

export import ArticleInlineAttribute = Occ.PresentationAttribute;

export interface AlternativeArticles {
  alternativesTo: ArticleRef;
  alternatives: AlternativeArticle[];
}

export interface AlternativeArticle {
  articleRef: ArticleRef;
}

export import EnvelopeInfo = Occ.ArticleEnvelope;

export interface ClassificationAttribute {
  code: string;
  rawValue?: string;
  unitString?: string;
  value?: string;
  name?: string;
}

export type AddonGroupRef = string;

export import AddonGroup = Occ.CatalogAddonGroup;

export import AddonNode = Occ.CatalogAddonNode;

export import A4SampleColor = Occ.A4SampleColor;

export import ProductDocument = Occ.ProductDocument;

export import ProductDocumentsWrapper = Occ.ProductDocumentsWrapper;

export import EcConformityProductDocument = Occ.EcConformityProductDocument;

export import EcConformityProductDocumentsPage = Occ.EcConformityProductDocumentsPage;

export import NewFreeStock = Occ.NewFreeStock;

export interface NewFreeArticleItem {
  id: string;
  newFreeArticle: NewFreeStock;
}

export import ConsignmentArticleStock = Occ.ConsignmentArticleStock;

export interface ColumnAttribute {
  name?: string;
  value?: string;
}
